.preloader{
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .preloader{
        span{
            .image{
                width: 55%;
            }
        }
    }
}