.About-Container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 155px 0px;
    background-color: black;
    color: white;
    font-family: AvenirLight;

    .About-Section-1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 62px;
        height: auto;

        .Title-Section-1 {
            display: flex;
            width: 100%;
            padding-left: 40px;
            justify-content: flex-end;
            align-items: flex-end;

            .Line-Box {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .Line {
                    content: "";
                    width: 100%;
                    height: 1px;
                    border: solid 2px #FDBF4A;
                    margin-bottom: 19px;
                }
            }

            h1 {
                width: 50%;
                margin: 0px;
                font-size: 72px;
            }
        }

        .Content-Section {
            padding: 125px;

            img {
                width: 100%;
                height: 100%;
            }

            p {
                margin-top: 40px;
                font-size: 22px;
                text-align: justify;

                span {
                    margin-top: 15px;
                    display: block;
                }
            }
        }
    }

    .About-Section-2 {
        width: 100%;
        padding-top: 25px;

        .Title-Section-2 {
            display: flex;
            width: 100%;
            padding-left: 40px;

            .Text-Container {
                width: 100%;

                h2 {
                    font-size: 72px;
                    margin: 0px;

                    span {
                        display: block;
                    }
                }

                .Line-Box {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .Line {
                        content: "";
                        width: 100%;
                        border: solid 2px #FDBF4A;
                        margin-bottom: 19px;
                        background-color: #FDBF4A;
                    }
                }

                .h-8 {
                    height: 8px;
                }

                .h-4 {
                    height: 4px;
                }

                .h-2 {
                    height: 2px;
                }

                .Title {
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }

        .Content-Section {
            padding: 125px;

            img {
                width: 100%;
                height: 100%;
            }

            p {
                margin-top: 40px;
                font-size: 24px;
                text-align: justify;
            }
        }
    }

    .About-Section-3 {
        .Accordion-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .accordion {
            .card {
                background-color: black;
                margin: 50px;

                img {
                    width: 100%;
                }

                .card-header {
                    display: flex;
                    font-size: 74px;
                    justify-content: space-between;
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;
                    border-radius: 0px;

                    button {
                        border: 0px;
                    }
                }
            }
        }
    }

    .About-Section-4 {
        padding: 60px;

        h2 {
            font-size: 72px;
        }

        .Text-Section {
            padding: 20px 120px;

            p {
                font-size: 24px;

                span {
                    display: block;
                    margin-top: 20px;
                }
            }
        }

        .Accordion-Section {
            padding: 100px;

            .accordion {
                .card-box {
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;
                    padding: 40px 0px;

                    .card {
                        background-color: black;

                        .Line {
                            content: "";
                            width: 100%;
                            height: 1px;
                            border: solid 1px white;
                            margin: 40px 0px; 
                        }

                        .card-header {
                            padding: 0px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border: 0px;
                            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                          
                            button {
                                border: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                            }

                            .d-none {
                                display: none;
                            }

                            span {
                                font-size: 64px;
                                color: white;
                                display: flex;
                                justify-self: center;
                            }

                        }

                        .white {
                            background-color: white;
                            color: black;

                            span {
                                color: black;

                            }
                        }

                        .card-body {
                            background-color: white;
                            color: black;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 968px) {
    .About-Container {
        .About-Section-3 {

            .accordion {
                .card {
                    background-color: black;
                    margin: 30px 0px;

                    img {
                        width: 100%;
                    }

                    .card-header {
                        display: flex;
                        font-size: 74px;
                        justify-content: space-between;
                        border-top: 1px solid white;
                        border-bottom: 1px solid white;
                        border-radius: 0px;

                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .About-Container {
        .About-Section-1 {
            padding: 0px;

            .Title-Section-1 {
                padding-left: 16px;

                h1 {
                    font-size: 46px;
                    width: 28%;
                }

                .Line-Box {
                    .Line {
                        margin-bottom: 12px;
                    }
                }
            }

            .Content-Section {
                padding: 16px;

                p {
                    font-size: 16px;
                }
            }
        }

        .About-Section-2 {
            .Title-Section-2 {
                padding-left: 16px;

                .Text-Container {
                    .Title {
                        h2 {
                            font-size: 42px;
                        }

                        .Line {
                            margin-bottom: 11px;
                        }
                    }
                }
            }

            .Content-Section {
                padding: 16px;

                p {
                    font-size: 16px;
                }
            }
        }

        .About-Section-3 {
            padding: 40px 16px;

            .accordion {
                .card {
                    margin: 16px;

                    .card-header {
                        font-size: 26px;

                        p {
                            margin: 0px;
                        }
                    }
                }
            }
        }

        .About-Section-4 {
            padding: 40px 16px;

            h2 {
                font-size: 42px;
            }

            .Text-Section {
                padding: 16px;

                p {
                    font-size: 16px;
                }
            }

            .Accordion-Section {
                padding: 16px;

                .accordion {
                    .card-box {
                        .card {
                            .card-header {
                                border: none;

                                img {
                                    width: 50%;
                                    height: 50%;
                                }

                                button {
                                    span {
                                        font-size: 42px;
                                    }
                                }

                            }

                            .accordion-collapse {
                                .card-body {
                                    p {
                                        margin-top: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .About-Container{
        .About-Section-1{
            .Title-Section-1{
                h1{
                    font-size: 100px;
                }
                .Line-Box{
                    .Line{
                        height: 10px;
                        background-color: #FDBF4A;
                        margin-bottom: 27px;
                    }
                }
            }
            .Content-Section{
                p{
                    font-size: 38px;
                }
            }
        }
        .About-Section-2{
            .Title-Section-2{
                .Text-Container{
                    .Title{
                        h2{
                            font-size: 100px;
                        }
                        .Line-Box{
                            .Line{
                                margin-bottom: 27px;
                            }
                        }
                    }
                }
            }
            .Content-Section{
                p{
                    font-size: 38px;
                }
            }
        }
        .About-Section-3{
            .accordion{
                .card{
                    .card-header{
                        p{
                            font-size: 78px;
                        }
                    }
                    .card-body{
                        h3{
                            font-size: 48px;
                        }
                        .Accordion-content{
                            .card{
                                width: 48rem !important;
                                .card-body{
                                    .h5{
                                        font-size: 42px;
                                    }
                                    p{
                                        font-size: 38px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .About-Section-4{
            h2{
                font-size: 82px;
            }
            .Text-Section{
                p{
                    font-size: 38px;
                }
            }
            .Accordion-Section{
                .accordion{
                    .card-box{
                        .card{
                            .card-header{
                                img{
                                    width: 30%;
                                }
                            }
                            .card-body{
                                h3{
                                    font-size: 52px;
                                }
                                p{
                                    font-size: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}