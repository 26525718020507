.Who-Container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 155px;
    background-color: black;
    color: white;

    .Section-1 {
        height: auto;
        padding-top: 62px;
        font-family: AvenirLight;

        .Title-Section {
            width: 100%;
            padding-left: 40px;
            display: flex;
            align-items: flex-end;

            h1 {
                font-size: 72px;
                margin: 0px;
                width: 39%;
            }
            .Line-Box{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .Line {
                    content: "";
                    width: 100%;
                    height: 1px;
                    border: solid 2px #FDBF4A;
                    margin-bottom: 19px;
                }
            }
        }

        .Image-Section {
            padding: 55px 150px;
            video{
                width: 100%;
                cursor: none;
            }
        }
    }

    .Section-2 {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 55px 120px;
        font-family: AvenirLight;

        h2 {
            font-size: 44px;
        }

        .Line-wht {
            content: "";
            height: 1px;
            border: solid 1px white;
            margin-bottom: 19px;
        }

        .space-between {
            justify-content: space-between;
        }

        .Content {
            display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
            padding: 108px 0px;

            .m-133 {
                margin-left: 133px;
            }

            .flex-wrap {
                flex-wrap: wrap;
            }

            .mt-108 {
                margin-top: 108px;
            }

            .Card {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-family: AvenirLight;

                h3 {
                    padding-top: 37px;
                    width: 325px;
                    display: flex;
                    justify-content: center;
                    font-size: 26px;
                    text-align: center;
                }

                img {
                    width: 50%;
                }
            }
        }
    }
}

.Buttons {
    background-color: transparent;
    border: none;
    color: #FDBF4A;
    font-size: 40px;
}

.Buttons:hover {
    background-color: unset;
}

.Buttons:active {
    background-color: transparent !important;
}

#who {
    .modal-content {
        align-items: center;
    }

    .modal-body {
        background-color: #E2E2E2;
        width: 800px;
        border: 3px solid black;

        h3 {
            font-size: 14px;
            font-family: AvenirMedium;
        }

        h2 {
            padding-top: 30px;
            font-family: AvenirBlack;
        }

        p {
            font-family: AvenirLight;
            font-size: 20px;
            padding-top: 23px;
            line-height: 22px;
        }
    }
}


@media (max-width: 768px) {
    .Who-Container {
        .Section-1 {
            padding-top: 0px;

            .Title-Section {
                padding-left: 16px;

                h1 {
                    font-size: 42px;
                    width: 60%;
                }
                .Line-Box{
                    .Line {
                        margin-bottom: 10px;
                    }
                }
            }

            .Image-Section {
                padding: 20px;
                video{
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .Section-2 {
            padding: 20px 16px;

            .Content {
                padding: 80px 0px;
                grid-template-columns: repeat(1, 1fr);

                .Card {
                    margin-top: 40px;
                }

                .m-133 {
                    margin-left: 0px;
                }
            }
        }
    }

    #who{
        .modal-dialog {
            justify-content: center;
        }
        .modal-body {
            width: auto;
            height: auto;

            h2 {
                padding-top: 8px;
            }

            p {
                padding-top: 8px;
                font-size: 16px;
            }
        }
    }
}
@media (min-width: 2048px) {
    .Who-Container{
        .Section-1{
            .Title-Section{
                h1{
                    font-size: 82px;
                    width: 31%;
                }
            }
            .Line-Box{
                .Line{
                    background-color: #FDBF4A;
                    height: 5px;
                    margin-bottom: 20px;
                }
            }
        }
        .Section-2{
            h2{
                font-size: 52px;
            }
            .Content{
                .Card{
                    h3{
                        font-size: 38px;
                    }
                    button{
                        font-size: 64px;
                    }
                }
            }
        }
    }
    #who{
        .modal-dialog {
            justify-content: center;
        }
        .modal-body {
            width: 1000px;
            height: auto;
            h3{
                font-size: 26px;
            }
            h2 {
                padding-top: 28px;
                font-size: 48px;
            }

            p {
                padding-top: 8px;
                font-size: 28px;
                line-height: 42px;
            }
        }
    }
}