.Footer-Container {
    width: 100%;
    height: 60vh;
    font-family: AvenirMedium;

    .Footer-Wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .Footer-Top-Section {
            display: flex;
            justify-content: space-between;
            padding: 40px;

            .Top-Left-Section {
                width: 50%;

                h1 {
                    font-size: 64px;
                    margin: 0px;
                    font-family: AvenirMedium;
                    font-weight: 600;
                }

                span {
                    display: block;
                    line-height: 52px;
                }
            }

            .Top-Right-Section {
                display: flex;
                width: 50%;
                justify-content: space-evenly;
                a{
                    color: black;
                    text-decoration: none;
                }
                .fixed {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    color: black;
                    text-decoration: none;
                }

                p {
                    font-family: AvenirMedium;
                    font-weight: 500;
                    font-size: 22px;
                    margin: 0px;
                }

                span {
                    font-size: 14px;
                    margin-top: 5px;
                    display: block;
                    text-align: end;
                }

                .padding-20 {
                    padding-left: 20px;
                }

                .d-flex-flex-column {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
        }

        .Footer-Bottom-Section {
            display: flex;
            justify-content: flex-start;
            padding: 40px;

            p {
                font-family: AvenirLight;
                font-weight: 600;
                font-size: 22px;
                margin: 0px;

                span {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .Footer-Container{
        .Footer-Wrapper{
            .Footer-Top-Section{
                padding: 40px 16px;
                display: flex;
                flex-direction: column;
                .Top-Left-Section{
                    padding-bottom: 20px;
                    img{
                        width: 55%;
                    }
                }
                .Top-Right-Section{
                    width: 83%;
                    flex-direction: column;
                    align-items: flex-start;
                    .padding-20{
                        padding-left: 0px;
                        padding-top: 20px;
                    }
                    .d-flex-flex-column{
                        align-items: flex-start;
                    }
                    .fixed{
                        align-items: flex-start;
                    }
                    span{
                        text-align: start;
                    }
                }
            }
            .Footer-Bottom-Section{
                padding: 40px 16px;
                .Left-Text-Section{
                    p{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1280px) {
    .Footer-Container{
        .Footer-Wrapper{
            .Footer-Top-Section{
                .Top-Right-Section{
                    width: 70%;
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .Footer-Container{
        .Footer-Wrapper{
            .Footer-Top-Section{
                .Top-Left-Section{

                }
                .Top-Right-Section{
                    width: 75%;
                    p{
                        font-size: 36px;
                    }
                    span{
                        font-size: 28px;
                    }
                }
            }
            .Footer-Bottom-Section{
                .Left-Text-Section{
                    p{
                        font-size: 38px;
                    }
                }
            }
        }
    }
}