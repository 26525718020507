.Projects-Container {
    width: 100%;
    height: auto;
    background-color: black;
    color: white;
    font-family: AvenirLight;
    padding-top: 160px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    .Section-1 {
        .Title-Container {
            display: flex;
            justify-content: space-between;
            padding-left: 40px;
            align-items: flex-end;

            h1 {
                font-size: 72px;
                width: 40%;
                margin: 0px;
                font-weight: 400;
            }
            .Line-Box {
                width: 100%;
                display: flex;
                justify-content: flex-end;

            .Line {
                content: "";
                width: 100%;
                height: 2px;
                background-color: #FDBF4A;
                border: 1px solid #FDBF4A;
                margin-bottom: 16px;
            }
        }
        }

        img {
            padding: 40px;
            width: 100%;
            height: 100%;
        }
    }

    .Section-2 {
        display: flex;
        flex-direction: column;
        padding: 40px;

        .Accordion-Section {
            .accordion {
                .card {
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    border-radius: 0px;
                    margin: 40px 0px;
                    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

                    .card-header {
                        display: flex;
                        justify-content: space-between;
                        font-size: 72px;
                        border: 0px;
                        align-items: center;

                        h2 {
                            font-size: 72px;
                            margin: 0px;
                        }

                        button {
                            border: 0px;
                        }
                    }

                    .accordion-collapse {
                        .card-body {
                            background-color: white;
                            color: black;
                            height: 127vh;
                            padding: 0px;
                            .react-slideshow-container{
                                display: none;
                            }
                            h3{
                                font-size: 48px;
                                margin-bottom: 40px;
                                padding: 40px 20px;
                            }
                            .Desc{
                                font-size: 20px;
                                padding: 0px 20px;
                            }
                                .scroll-horizontal {
                                    position: relative;
                                    --v-offset: 60px;
                                    --curve-height: 120px;
    
                                    &:before,
                                    &:after {
                                        content: "";
                                        display: block;
                                        background: white;
                                        width: calc(100vw + 2 * var(--v-offset));
                                        height: var(--curve-height);
                                        position: absolute;
                                        border-radius: 50%;
                                        left: calc(-1 * var(--v-offset));
                                        right: calc(-1 * var(--v-offset));
                                    }
    
                                    &:before {
                                        top: calc(-0.6 * var(--curve-height));
                                    }
    
                                    &:after {
                                        bottom: calc(-0.6 * var(--curve-height));
                                    }
                                }
                            .Detail-Section{
                                display: flex;
                                flex-direction: column;
                                width: 50%;
                                padding: 20px;
                                .sub-title{
                                    font-size: 22px;
                                }
                                p{
                                    span{
                                        display: block;
                                    }
                                }
                                .Top-Section{
                                    display: flex;
                                    width: 40%;
                                    justify-content: space-between;
                                    .Column{
                                        display: flex;
                                        flex-direction: column;

                                    }
                                }
                                .Bottom-Section{
                                    display: flex;
                                    width: 47%;
                                    justify-content: space-between;
                                    .Column{
                                        display: flex;
                                        flex-direction: column;
                                    }
                                }
                            }
                        }
                    }
                }


                .white {
                    background-color: white;
                    color: black;

                    span {
                        background-color: white;
                        color: black;
                        border: 0px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .Projects-Container{
        .Section-1{
            .Title-Container{
                padding-left: 16px;
                h1{
                    font-size: 46px;
                    width: 88%;
                }
                .Line-Box{
                    .Line{
                        height: 2px;
                        margin-bottom: 11px;
                    }}
            }
            img{
                padding: 20px;
            }
        }
        .Section-2{
            padding: 0px 16px;
            .Accordion-Section{
                .accordion{
                    .card{
                        .card-header{
                            font-size: 32px;
                            h2{
                                font-size: 32px;
                            }
                            button{
                                padding: 0px;
                            }
                        }
                        .accordion-collapse{
                            .card-body{
                                height: auto;
                                .react-slideshow-container{
                                    display: block;
                                    img{
                                        width: 100%;
                                    }
                                    button{
                                        display: none;
                                    }
                                }
                                h3{
                                    font-size: 36px;
                                    margin-bottom: 0px;
                                }
                                .Desc{
                                    font-size: 16px;
                                }
                                .scroll-horizontal{
                                    height: 35vh !important;
                                    display: none;
                                }
                                .Detail-Section{
                                    width: 100%;
                                    font-size: 14px;
                                    .Top-Section{
                                        width: 75%;
                                    }
                                    .Bottom-Section{
                                        width: 90%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .Projects-Container{
        .Section-1{
            .Title-Container{
                h1{
                    font-size: 86px;
                    width: 34%;
                }
            }
            .Line-Box{
                .Line{
                    margin-bottom: 22px;
                }
            }
        }
        .Section-2{
            .Accordion-Section{
                .accordion{
                    .card{
                        .accordion-collapse{
                            .card-body{
                                h3{
                                    font-size: 68px;
                                }
                                .Desc{
                                    font-size: 32px;
                                }
                                .Detail-Section{
                                    p{
                                        font-size: 26px;
                                    }
                                    span{
                                        font-size: 32px;
                                    }
                                    .Bottom-Section{
                                        width: 53%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}