.Nav-Container {
    background-color: black;
    position: fixed;
    width: 100%;
    height: 120px;

    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: AvenirLight;
    text-transform: uppercase;
    z-index: 99999;
    padding: 40px 40px;

    .navbar--hidden {
        top: -75px;
    }

    .Nav-Left-Container {
        display: flex;
        width: 100%;
        .image {
            width: 70%;
            height: 100%;
            opacity: 1;
        }
    }

    .Nav-Right-Container {
        display: flex;
        z-index: 999999;
        width: 100%;
        justify-content: center;
        .Mobile {
            display: none;
        }

        .Desktop {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .dropdown {
                background-color: transparent;
                border-color: transparent;
                padding: 0px;
                font-family: AvenirLight;
                text-transform: uppercase;
                color: white;
                background-color: black;
                .a{
                    padding: 0px;
                }
                button{
                    font-size: 14px;
                }
                a{
                    text-transform: capitalize;
                    span{
                        font-size: 16px;
                        display: block;
                    }
                }
                .dropdown:active {
                    background-color: transparent;
                    border-color: transparent;
                }
            }

            a {
                color: white;
                text-decoration: none;
                font-size: 14px;

                span {
                    font-size: 14px;
                }
            }

            .clr-wht-padding-0 {
                color: white;
                padding: 0px;
                font-size: 16px;
                margin-top: 2px;
            }

            .clr-wht-padding-0:hover {
                background-color: transparent;
            }
        }
    }
}

@keyframes slide-on {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-off {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.show-on {
    animation: slide-on;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(1, 0.885, 0.32, 1);
}

.show-off {
    animation: slide-off;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(1, 0.885, 0.32, 1);
}

#Navbar {
    margin: 0px;
    .modal-body{
        height: 100vh;
        padding-top: 120px;
        padding: 0px;
        background-color: black;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: AvenirLight;
        .Buttons{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            a{
                color: white;
                text-decoration: none;
                margin-top: 20px;
                span{
                    font-size: 28px
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .Nav-Container{
        height: 80px;
        padding: 16px 20px;
        .Nav-Left-Container{
            .image{
                width: 50%;
                height: 100%;
            }
        }
        .Nav-Right-Container{
            justify-content: flex-end;
            .Desktop{
                display: none;
            }
            .Mobile{
                display: flex;
                .Nav-btn {
                    flex-direction: column;
                    align-items: flex-end;
                    display: flex;
                    .Line-Top {
                        content: "";
                        width: 40px;
                        border: 1px solid white;
                        height: 1px;
                        background-color: white;
                    }
    
                    .Line-Middle {
                        content: "";
                        width: 30px;
                        border: 1px solid white;
                        height: 1px;
                        margin-top: 5px;
                    }
    
                    .Line-Bottom {
                        content: "";
                        width: 20px;
                        border: 1px solid white;
                        height: 1px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .Nav-Container{
        height: 160px;
        .Nav-Left-Container{
            .image{
                width: 100%;
            }
        }
        .Nav-Right-Container{
            .Desktop{
                a{
                    span{
                        font-size: 32px;
                    }
                }
                .dropdown{
                    button{
                        font-size: 32px;
                    }
                    .clr-wht-padding-0 {
                        a{
                            font-size: 32px;
                            span{
                                font-size: 32px;    

                            }
                        }
                    }
                }
            }
        }
    }
}