.Home-Container {
    width: 100%;
    height: auto;
    background-color: black;
    color: white;
    padding-bottom: 100px;
    .Home-Wrapper {
        .Home-Section-1 {
            height: auto;
            width: 100%;
            padding: 200px 0px 0px 0px;

            .image {
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            h1 {
                font-size: 64px;
                padding: 52px 75px;
                font-family: AvenirMedium;

                span {
                    display: block
                }

                
            }


            .Img-Container {
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;

                .block {
                    display: block;
                    position: absolute;
                    top: 420px;
                    left: 100px;
                    font-size: 36px;
                    font-family: AvenirBlack;
                    letter-spacing: 0.04em;
                }
            }

            .Video-Container {
                width: 100%;
            }
        }

        .Home-Section-2 {
            height: 100vh;
            width: 100%;
            padding: 80px 40px;
            display: flex;
            justify-content: center;

            .Content-Section {
                height: 100%;
                width: 0%;
                display: flex;
                position: relative;
                overflow: hidden;
                background-color: #FDBF4A;

                .Section-2-Left {
                    width: 45%;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        opacity: 0;
                    }
                }

                .Section-2-Right {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 100px;
                    overflow: hidden;
                    color: black;

                    .p-sec-2 {
                        font-size: 32px;
                        margin: 0px;
                        letter-spacing: 0.04em;
                        font-family: AvenirMedium;

                        span {
                            display: block;
                            line-height: 1;
                        }

                        .margin-10 {
                            margin-top: 50px;
                        }

                    }

                    .btn-sec-2 {
                        width: 169.8px;
                        margin-top: 35px;
                        background-color: white;
                        color: black;
                        box-shadow: inset 0px 5px 5px rgba(85, 91, 110, 0.15);
                        border-radius: 30px;
                        border: 0px;
                        font-family: AvenirMedium;
                    }
                }

                .block {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    position: absolute;
                    top: 350px;
                    right: 40px;

                }
            }
        }

        .Home-Section-3 {
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 80px 40px;

            .Our-Vision {
                width: 100%;
                display: flex;
                flex-direction: column;

                .context {
                    display: flex;
                    padding: 40px 110px;

                    .Section-3-Left {
                        width: 60%;

                        p {
                            font-family: AvenirMedium;
                            font-size: 36px;
                        }
                    }

                    .Section-3-Right {
                        width: 100%;

                        p {
                            font-family: AvenirMedium;
                            font-size: 24px;
                        }
                    }
                }
            }

            .Our-Vision::before {
                content: "";
                width: 100%;
                height: 1px;
                border: 1px solid white;
                background-color: white;
                display: flex;
                flex-direction: column;
            }

            .Our-Vision::after {
                content: "";
                width: 100%;
                height: 1px;
                border: 1px solid white;
                background-color: white;
                display: flex;
                flex-direction: column;
            }

            .Our-Mission {
                width: 100%;
                display: flex;
                flex-direction: column;

                .context {
                    display: flex;
                    padding: 40px 110px;

                    .Section-3-Left {
                        width: 60%;

                        p {
                            font-family: AvenirMedium;
                            font-size: 36px;
                        }
                    }

                    .Section-3-Right {
                        width: 100%;

                        p {
                            font-family: AvenirMedium;
                            font-size: 24px;
                        }
                    }
                }
            }

            .Our-Mission::after {
                content: "";
                width: 100%;
                height: 1px;
                border: 1px solid white;
                background-color: white;
                display: flex;
            }
        }

        .Home-Section-4 {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            font-family: AvenirMedium;
            padding: 0px 75px;

            .Our-Projects {
                width: 50%;
                padding-bottom: 100px;

                h2 {
                    color: #FDBF4A;
                    font-size: 72px;
                }

                p {
                    font-size: 24px;
                }
            }

            .Box-1 {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .Kalimantan {
                    padding-top: 200px;
                    max-width: 550px;
                    display: flex;
                    position: relative;
                    justify-content: flex-end;

                    .Rectangle {
                        width: 685px;
                        height: 636px;
                        background-color: #FDBF4A;
                        position: absolute;
                        top: 18%;
                        right: -4%;
                    }

                    img {
                        z-index: 999;
                    }

                    .Text {
                        position: absolute;
                        top: 17.5%;
                        left: -132%;

                        .a {
                            color: white;
                            text-decoration: none;

                            img {
                                transform: rotate(180deg);
                            }

                            p {
                                font-family: AvenirLight;
                                text-transform: uppercase;
                                font-size: 72px;
                                line-height: 72px;
                                letter-spacing: 0.04em;

                                span {
                                    display: block;
                                }
                            }
                        }

                        .a:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .Box-2 {
                width: 100%;
                display: flex;
                justify-content: flex-start;

                .Bali {
                    padding: 200px 103px;
                    max-width: 550px;
                    display: flex;
                    flex-direction: row-reverse;
                    position: relative;
                    justify-content: flex-end;

                    .Rectangle {
                        width: 549px;
                        height: 620px;
                        background-color: #FDBF4A;
                        position: absolute;
                        top: 14.5%;
                        right: 0%
                    }

                    img {
                        z-index: 999;
                    }

                    .Text {

                        display: flex;
                        align-items: flex-end;
                        flex-direction: column;
                        position: absolute;
                        top: 69%;
                        left: 112%;
                        width: 100%;

                        .a {
                            color: white !important;
                            text-decoration: none;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                            p {
                                font-family: AvenirLight;
                                text-transform: uppercase;
                                font-size: 62px;
                                line-height: 62px;
                                letter-spacing: 0.04em;
                                text-align: end;
                                color: white;
                                text-decoration: none;

                                span {
                                    display: block;
                                }
                            }
                        }

                        .a:hover {
                            text-decoration: underline;
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: 968px) {
    .Home-Container {
        .Home-Wrapper {
            .Home-Section-2 {
                .Content-Section {
                    height: auto;

                    .Section-2-Right {
                        p {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 820px) {
    .Home-Container {
        .Home-Wrapper {
            .Home-Section-4 {
                .Box-1 {
                    .Kalimantan {
                        .Rectangle {
                            width: 158px;
                            height: 185px;
                            top: -20%;
                        }

                        .Text {
                            position: absolute;
                            left: 3%;
                            top: -23%;

                            .a {
                                p {
                                    font-size: 12px;
                                    line-height: 25px;
                                    margin: 0px;
                                }

                                img {
                                    width: 35%;
                                }
                            }
                        }

                        img {
                            width: 65%;
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 768px) {
    .Home-Container {
        .Home-Wrapper {
            .Home-Section-1 {
                padding: 160px 0px 0px 0px;

                h1 {
                    font-size: 38px;
                    padding: 0px 16px;
                }
            }

            .Home-Section-2 {
                height: auto;
                padding: 30px 16px;

                .Content-Section {
                    height: auto;

                    .Section-2-Left {
                        img {
                            width: 100%;
                            height: 100%
                        }

                        ;
                    }

                    .Section-2-Right {
                        padding: 0px;

                        .p-sec-2 {
                            font-size: 11px;

                            span {
                                margin: 20px !important;
                            }
                        }

                        .btn {
                            font-size: 11px;
                            width: 60px;
                            margin-top: 0px;
                            margin-left: 20px;
                            margin-bottom: 20px;

                        }
                    }

                    .margin-10 {
                        margin-top: 20px;
                    }
                }
            }

            .Home-Section-3 {
                height: auto;
                padding: 0px 16px;

                .Our-Vision {
                    .context {
                        padding: 20px 16px;

                        .Section-3-Left {
                            p {
                                font-size: 16px;
                            }
                        }

                        .Section-3-Right {
                            p {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .Our-Mission {
                    .context {
                        padding: 20px 16px;

                        .Section-3-Left {
                            p {
                                font-size: 16px;
                            }
                        }

                        .Section-3-Right {
                            p {
                                font-size: 16px;
                                text-align: left;
                            }
                        }
                    }
                }
            }

            .Home-Section-4 {
                padding: 40px 16px;
                .Our-Projects {
                    width: 100%;

                    h2 {
                        font-size: 42px;
                    }

                    p {
                        font-size: 16px;
                    }
                }

                .Box-1 {
                    .Kalimantan {
                        padding: 0px;

                        .Rectangle {
                            width: 158px;
                            height: 185px;
                            top: -20%;
                            position: absolute;
                        }

                        .Text {
                            position: absolute;
                            left: 3%;
                            top: -23%;

                            .a {
                                p {
                                    font-size: 22px;
                                    line-height: 25px;
                                    margin: 0px;
                                }

                                img {
                                    width: 35%;
                                }
                            }
                        }

                        img {
                            width: 65%;
                        }
                    }
                }

                .Box-2 {
                    .Bali {
                        padding: 100px 12px;

                        .Text {
                            top: 62%;
                            left: -3%;

                            .a {
                                p {
                                    font-size: 22px;
                                    line-height: 25px;
                                }

                                img {
                                    width: 35%;
                                }
                            }
                        }

                        img {
                            width: 54%;
                        }

                        .Rectangle {
                            width: 176px;
                            height: 185px;
                            top: 23.5%;
                            left: -1.5%;
                            position: absolute;
                        }
                    }
                }

            }
        }
    }
}

@media  (min-width: 1280px) and (max-width: 1280px)  {
    .Home-Container{
        .Home-Wrapper{
            .Home-Section-4{
                .Box-1{
                    .Kalimantan{
                        max-width: 450px;
                        .Text{
                            top: 20%;
                            left: -135%;
                            img{
                                width: unset;
                            }
                        }
                        .Rectangle{
                            width: 465px;
                            height: 530px;
                            top: 21%;
                            right: -4%;
                        }
                        img{
                            width: 150%;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 2048px) {
    .Home-Container{
        .Home-Wrapper{
            .Home-Section-2{
                .Content-Section{
                    .Section-2-Right{
                        .p-sec-2{
                            font-size: 52px;
                        }
                        .btn-sec-2{
                            width: 220px;
                            font-size: 29px;
                        }
                    }
                }
            }
            .Home-Section-3{
                height: 70vh;
                .Our-Vision{
                    .context{
                        .Section-3-Left{
                            p{
                                font-size: 62px;
                            }
                        }
                        .Section-3-Right{
                            p{
                                font-size: 38px;
                            }
                        }
                    }
                }
                .Our-Mission{
                    .context{
                        .Section-3-Left{
                            p{
                                font-size: 62px;
                            }
                        }
                        .Section-3-Right{
                            p{
                                font-size: 38px;
                            }
                        }
                    }
                }
            }
            .Home-Section-4{
                .Our-Projects{
                    h2{
                        font-size: 86px;
                    }
                    p{
                        font-size: 36px;
                    }
                }
            }
        }
    }
}