.Contact-Container {
    height: auto;
    display: flex;
    flex-direction: column;
    font-family: AvenirLight;
    background-color: black;
    color: white;
    padding-top: 160px;

    .Section-1 {
        height: auto;

        img {
            width: 100%;
            padding: 40px;
        }

        .Contact-Title {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-left: 40px;

            .Line-Box {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .Line {
                    content: "";
                    width: 100%;
                    height: 2px;
                    border: solid 2px #FDBF4A;
                    background-color: #FDBF4A;
                    margin-bottom: 19px;
                }
            }

            h1 {
                width: 45%;
                margin: 0px;
                font-size: 72px;
            }
        }
    }

    .Section-2 {
        height: auto;

        .Section-2-Title {
            padding: 40px 60px;
            display: flex;
            flex-direction: column;

            span {
                font-size: 24px;
                font-weight: 400;
            }

            a {
                color: #FDBF4A;

                span {
                    font-size: 48px;
                }
            }
        }

        .Form-Section {
            margin: 0px 40px;
            padding: 60px 500px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            display: flex;
            justify-content: center;
            .form {
                display: flex;
                flex-direction: column;
                max-width: 400px;
                .mb-3{
                    width: 400px;
                }
                .btn {
                    align-self: center;
                    background-color: black;
                    border: 1px solid #FDBF4A;
                    color: #FDBF4A;
                    border-radius: 0px;
                    width: 175px;
                }

                .form-control {
                    color: white;
                    background-color: black;
                    border-radius: 0px;
                    border: 1px solid white;

                    ::placeholder {
                        color: white;
                    }
                }
            }
        }

        .Maps-Container {
            padding: 40px 40px;
            display: flex;
            position: relative;

            .Maps-Detail {
                display: flex;
                flex-direction: column;
                font-family: AvenirLight;
                justify-content: space-between;
                padding: 40px 40px 40px 0px;
                width: 40%;

                .Maps-Title {
                    font-size: 42px;
                }

                .Maps-Desc {
                    font-size: 22px;

                    span {
                        display: block;
                    }
                }

                .Button-Container-Desktop {
                    background-color: #FDBF4A;
                    width: 300px;
                    height: 80px;
                    font-size: 38px;
                    border: 1px solid black;

                    a {
                        color: black;
                        text-decoration: none;
                    }

                    a:hover {
                        color: black;
                    }
                }

                button:hover {
                    background-color: white;
                    color: black;
                    border: 1px solid black;
                }
            }

            .Maps {
                width: 100%;

                .Button-Container-Mobile {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    left: 40%;
                    bottom: 10%;
                    display: none;

                    button {
                        background-color: black;
                        color: white;
                        width: 300px;
                        height: 80px;
                        font-size: 38px;
                        border: 1px solid white;

                        a {
                            color: white;
                            text-decoration: none;
                        }

                        a:hover {
                            color: black;
                        }
                    }

                    button:hover {
                        background-color: white;
                        color: black;
                        border: 1px solid black;
                    }
                }
            }

            a {
                img {
                    padding: 40px 0px;
                    margin-top: 0px;
                }
            }


        }

        img {
            width: 100%;
            margin-top: 60px;
            padding: 40px 40px;
        }
    }
}

@media (max-width: 768px) {
    .Contact-Container {
        .Section-1 {
            .Contact-Title {
                margin-left: 16px;

                h1 {
                    font-size: 40px;
                    width: 47%;
                }

                .Line-Box {
                    .Line {
                        margin-bottom: 11px;
                    }
                }
            }

            img {
                padding: 20px;
            }
        }

        .Section-2 {
            .Section-2-Title {
                padding: 40px 16px;

                span {
                    font-size: 16px;
                }

                a {
                    span {
                        font-size: 22px;
                    }
                }
            }

            .Form-Section {
                padding: 60px 16px;
                margin: 0px 20px;
                justify-content: center;
                .form {
                    .mb-3{
                        width: 300px;
                    }
            }
        }

            .Maps-Container {
                padding: 0px 00px;
                flex-direction: column;

                .Maps-Detail {
                    width: 100%;
                    align-content: center;
                    padding: 20px 20px;
                    .Maps-Title{
                    font-size: 34px;
                    }
                    .Maps-Desc{
                        font-size: 18px;
                        margin-top: 20px;
                    }
                    .Button-Container-Desktop {
                        display: none;
                    }
                }

                .Maps {
                    .Button-Container-Mobile{
                        left: 30%;
                        bottom: 8%;
                        display: block;
                        button {
                            width: 150px;
                            height: 45px;
                            font-size: 15px;
                        }
                    }
                }

                a {
                    img {
                        padding: 15px 0px;
                    }
                }

            }

            img {
                margin: 20px 0px;
                padding: 20px 20px;
            }
        }
    }
}
@media (min-width: 2048px) {
    .Contact-Container{
        padding: 180px 0px;
        .Section-1{
            .Contact-Title{
                h1{
                    width: 32%;
                    font-size: 86px;
                }
            }
            img{
                padding: 40px 40px;
            }
        }
        .Section-2{
            .Section-2-Title{
                span{
                    font-size: 32px ;
                }
            }
            .Form-Section{
                .form{
                    max-width: 600px;
                    .mb-3{
                        width: 600px;
                    }
                }
            }
            .Maps-Container{
                .Maps-Detail{
                    .Maps-Title{
                        font-size: 56px;
                    }
                    .Maps-Desc{
                        font-size: 32px;
                    }
                }
            }
        }
    }
}