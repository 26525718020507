
@font-face {  
  font-family: 'AvenirBlack';  
  src:  url(./Assets/Fonts/Avenir/Avenir-Black.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirBlackOblique';  
  src:  url(./Assets/Fonts/Avenir/Avenir-BlackOblique.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirBook';  
  src:  url(./Assets/Fonts/Avenir/Avenir-Book.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirBookOblique';  
  src:  url(./Assets/Fonts/Avenir/Avenir-BookOblique.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirHeavy';  
  src:  url(./Assets/Fonts/Avenir/Avenir-Heavy.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirLight';  
  src:  url(./Assets/Fonts/Avenir/Avenir-Light.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirMedium';  
  src:  url(./Assets/Fonts/Avenir/Avenir-Medium.woff) format('woff');
}
@font-face {  
  font-family: 'AvenirRoman';  
  src:  url(./Assets/Fonts/Avenir/Avenir-Roman.woff) format('woff');
}
@font-face {  
  font-family: 'RiccioneExtraLight';  
  src:  url(./Assets/Fonts/Riccione/riccione-serial-extralight-regular.ttf) format('truetype');
}
@font-face {  
  font-family: 'RiccioneLight';  
  src:  url(./Assets/Fonts/Riccione/Riccione-Light.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-y: scroll;
  scrollbar-width: none !important;
}
body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
